<template>
  <div class="container">
    <img :src="oss+'logo.png'" class="logo"/>
    <div class="floats_right">
      <a @click="()=>{
        contentHtml = textInfo.rule
        contentHtmlModal = true
      }"><img :src="oss+'activity_rule_icon.png'"/></a>
      <router-link to="/service"><img :src="oss+'kefu_refund_icon.png'"/></router-link>
    </div>

    <div class="ui_refill">
      <div class="title_1">{{amount}}元享开盒子会员享</div>
      <div class="logsWrap" v-if="logsList.length">
        <vue-seamless-scroll :data="logsList" :class-option="optionHover" class="logs">
          <div class="item" v-for="(item,key) in logsList" :key="key">
            <img :src="(item.avatar=='' || item.avatar==null)?noheadimg:item.avatar" class="headimg"/><span>{{item.name}}的账户</span>
          </div>
        </vue-seamless-scroll>
      </div>
      <div class="fonts"><label>￥</label><count-to :start-val="start_val" :end-val="end_val" :duration="1000"></count-to></div>

      <div class="phone">
        <van-field v-model="json.phone" type="tel" :border="false" ref="phone" placeholder="请输入您的手机号码">
          <template #left-icon>
            <van-icon class-prefix="icon" name="mobile" size="20" color="#444"/>
          </template>
        </van-field>
      </div>
      <div class="paybtn"><van-button round block @click="onsubmit"></van-button></div>
      <div class="tips">
        <span><van-checkbox v-model="isread" icon-size="16px" checked-color="#FE2D55" shape="square">我已阅读并同意</van-checkbox></span>
        <a @click="()=>{
            contentHtml = textInfo.privacy_policy
            contentHtmlModal = true
          }">《用户隐私协议》</a>
        <a @click="()=>{
            contentHtml = textInfo.user_agreement
            contentHtmlModal = true
          }">《用户协议》</a>
      </div>

    </div>
    <div class="foot">
      <p>客服电话：4006178158</p>
      <p>{{textInfo.company_name}}</p>
    </div>

    <van-popup v-if="contentHtmlModal" v-model="contentHtmlModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" :close-on-click-overlay="false" class="contentPop">
      <div class="html" v-html="contentHtml"></div>
      <div class="close"><van-button type="primary" round color="#e15ae8" style="width:60%" @click="()=>{contentHtmlModal = false}">确定</van-button></div>
    </van-popup>

    <nopay v-if="RetentionModal" :visible="RetentionModal" :money="money" @close="()=>{
      this.clearTime()
      this.RetentionModal = false
    }" @onsubmit1="()=>{
      this.getCreateOrder(json)
    }" @onsubmit2="()=>{
      this.finishPay(json.order_sn)
    }"/>

  </div>
</template>
<script>

  import countTo from 'vue-count-to'
  import {isWeixin,getordernum} from '@/utils/utils'
  import {initCloud} from '@wxcloud/cloud-sdk'

  import nopay from './components/nopay'

  import {GetPayConfig,GetUserPrivate,CreateOrder,GetOrderStatus} from './services/services'
  export default {
    name:'activity',
    components: {countTo,nopay},
    data () {
      return {
        noheadimg:this.oss+'header.png',
        textInfo:{},
        start_val:20,
        end_val:200,
        logsList:[],
        money:{},
        optionHover:{
          step:0.4,
          limitMoveNum:2,
          hoverStop:false,
          direction:1,
          openWatch:true,
          singleHeight:20,
          singleWidth:0,
          waitTime:3000,
        },
        json:{
          id:0,
          pay_way:0,
          order_sn:'',
          phone:'',
          account:'',
          url:location.href
        },
        detail:{},
        contentHtmlModal:false,
        contentHtml:'',
        isread:true,
        timer:null,
        RetentionModal:false,//挽留
        amount :29.9
      }
    },
    created(){
      const {phone} = this.$route.query
      this.json={
        ...this.json,
        ...this.$route.query,
        phone:(phone==undefined?'':atob(phone))
      }
      this.isWeixin = isWeixin()?1:0
      // this.isWeixin = 1
      if(this.isWeixin){
        this.json.pay_way = 14
      }
      this.type = this.json.type==undefined ? undefined:this.json.type
    },
    mounted(){
      if(sessionStorage.getItem('order_info')){
        let order_info = JSON.parse(sessionStorage.getItem('order_info'))
        this.json={
          ...this.json,
          ...order_info
        }

        this.findOrderStatus(this.json.order_sn)
      }
      if(this.json.order_sn){
        this.findOrderStatus(this.json.order_sn)
      }

      this.findAnimationMoney()
      this.findpayConfig()
      this.finduserprivate()
      this.findlogList()
    },
    watch:{
      'json.phone':{
        handler:function(value){
          if(value.length == 11){
            if(!/^1[3456789]\d{9}$/.test(value)) {
              this.$toast('请输入正确的手机号码')
              this.$refs.phone.focus()
            }else{
              this.$refs.phone.blur()
            }
          }
        },
        deep:true
      }
    },
    methods:{
      findpayConfig(){
        GetPayConfig({type:this.type}).then(result => {
          if(result.data.code==1){
            this.detail = this.detail = result.data.data
            let payWay = this.detail.payConfig[0].payWay
            let pay=this.detail.payConfig.find(item=>item.payWay == payWay)
            const {pwyAccount} = pay
            this.json.pay_way=payWay
            this.json.account=pwyAccount

            let money=this.detail.goodsArray.find(item=>item.status==1)
            this.money=money
            this.json.id=money.id
            this.amount = money.price
            const {app_download_url,kefu_url} = this.detail
            let data = {app_download_url,kefu_url}
            sessionStorage.setItem('payConfig',JSON.stringify(data))
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      finduserprivate(){
        GetUserPrivate().then(result => {
          if(result.data.code==1){
            this.textInfo = result.data.data
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      findAnimationMoney(){
        this.timer = setInterval(()=>{
          this.end_val = 20
          setTimeout(()=>{
            this.end_val = 200
          },100)
        },3000)
      },
      findlogList(){
        this.logsList=[
          {avatar:'',name:'一**油1',time:'2分钟前',price:100},
          {avatar:'',name:'一**油2',time:'2分钟前',price:100},
          {avatar:'',name:'一**油3',time:'2分钟前',price:100},
          {avatar:'',name:'一**油4',time:'2分钟前',price:100},
          {avatar:'',name:'一**油5',time:'2分钟前',price:100},
          {avatar:'',name:'一**油6',time:'2分钟前',price:100}
        ]
      },
      onsubmit(){
        if(!this.isread){
          this.$toast('请阅读隐私协议与用户协议')
          return false
        }
        if(this.json.phone==''){
          this.$toast('请输入充值手机号')
          this.$refs.phone.focus()
          return false
        }else if(!/^1[3456789]\d{9}$/.test(this.json.phone)){
          this.$toast('请输入正确的手机号码')
          this.$refs.phone.focus()
          return false
        }
        this.$toast.loading({
          message: '正在提交订单...',
          forbidClick: true,
        })

        let order_sn = this.json.order_sn==''?getordernum(6):this.json.order_sn
        let json={
          ...this.json,
          order_sn:order_sn
        }
        this.getCreateOrder(json)
      },
      getCreateOrder(res){
        CreateOrder({...res,openid:(this.isWeixin?this.json.openid:undefined),pay_tag:((this.isWeixin || res.pay_way==14)?'wechat':'alipay')}).then(result => {
          if(result.data.code==1){
            sessionStorage.setItem('order_info',JSON.stringify(res))
            const {data} = result.data
            let pay_way = res.pay_way
            if(pay_way==14){
              location.href=data
              // this.payWeChat(data)
            }else if(pay_way==16){
              this.Alipay(data)
            }

          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      async payWeChat(orderId){
        let c1 = new this.initCloud().Cloud({
          // 必填，表示是未登录模式
          identityless: true,
          // 资源方 AppID
          resourceAppid: 'wxdada9bc45300a359',
          // 资源方环境 ID
          resourceEnv: 'cloud1-3gua9rkm875bd364',
        })

        await c1.init()
        const res =  await c1.callFunction({
          name: 'public',
          data: {
            action: 'getUrlScheme',
            path:'pages/index/index', // 想跳转的小程序路径
            orderId:orderId, // 预下单单号
          },
        }).then((res)=>{
          let result = res.result
          if(result.errCode==0){
            location.href = result.openlink
          }
        })
      },
      Alipay(res){
        const div = document.createElement("formdiv")
        div.innerHTML = res
        document.body.appendChild(div)
        document.forms["pay"].setAttribute("target", "_self")
        document.forms["pay"].submit()
        div.remove()
      },
      findOrderStatus(order_sn){
        GetOrderStatus({order_sn:order_sn}).then(result => {
          const {code} = result.data
          if(code==1){
            this.paysuccess = code
            clearInterval(this.timer)
            this.$router.push({path:'/success'})
          }else{
            if(!this.RetentionModal){
              this.RetentionModal = true
            }
            // this.$dialog.confirm({
            //   title: '确认支付情况',
            //   confirmButtonText:'已完成支付',
            //   cancelButtonText:'重新支付',
            //   closeOnClickOverlay:true
            // }).then(() => {
            //   this.finishPay(order_sn)
            // }).catch((res) => {
            //   if(res == 'overlay'){
            //     this.clearTime()
            //   }else{
            //     this.getCreateOrder(this.json)
            //   }
            // })
            if(this.timer == null){
              this.getTimerStatus(order_sn)
            }

          }
        })
      },
      getTimerStatus(order_sn){
        this.timer = setInterval(() => {
          if(this.count>=5){
            // this.create_status=1
            this.clearTime()
            return false
          }
          this.count=this.count+1
          this.findOrderStatus(order_sn)
        },3*1000)
      },
      finishPay(order_sn){//支付完成
        GetOrderStatus({order_sn:order_sn}).then(result => {
          const {code} = result.data
          if(code==1){
            this.paysuccess = code
            this.clearTime()
            this.$router.push({path:'/success'})
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
        // location.href = 'https://api.youzuanmy.vip/xd/paysuccess.html?orderNo='+this.json.order_sn
      },
      clearTime(){
        clearInterval(this.timer)
        sessionStorage.removeItem('order_info')
      },
      onSubmitPay(status){
        switch (status) {
          case 1:
            this.finishPay()
            break;
          case 0:
            this.getCreateOrder(this.json)
            break;
        }
      },

    }
  }

</script>
<style scoped lang="less">
  .container{min-height:100vh;background:linear-gradient(119deg,#6cb2ff,#4b97ff);position:relative;
    .floats_right{position:fixed;right:0px;top:74px;z-index:9;width:28px;
      img{width:100%;}
      a{margin:12px 0px;display:inline-block;}
    }
    .logo{width:30px;position:absolute;left:10px;top:10px;z-index:1;}
    .ui_refill{background:url('@{static-dir}bg.png') no-repeat left top;background-size:100% auto;height:586px;padding:0px 12%;}
    .title_1{color:hsla(0,0%,100%,.5);text-align:center;padding:20px 0px;font-size:12px;}
    .logsWrap{margin:52px auto 0px;width:70%;padding:4px 0px;
      .logs{height:20px;overflow:hidden;
        .item{display:flex;align-items:center;background:url('@{static-dir}mb.png') no-repeat right center;background-size:70px auto;color:#fff;font-size:12px;
          .headimg{width:20px;height:20px;border-radius:100%;display:block;margin-right:4px;}
        }
      }
    }
    .fonts{margin-top:100px;text-align:center;color:#ff0052;font-weight:700;display:flex;justify-content:center;height:100px;line-height:100px;
      label{padding-top:30px;font-size:36px;margin-right:-4px;}
      span{font-size:120px;}
    }
    .phone{margin-top:60px;
      .van-cell{border-radius:100px;border:1px solid #ff0052;}
    }
    .paybtn{margin-top:10px;
      .van-button{background:url('@{static-dir}btn.png') no-repeat center center;background-size:100% auto;height:60px;line-height:60px;-webkit-animation:paybtn_scalc 1s ease infinite;animation:paybtn_scalc 1s ease infinite;}
    }
    .tips{margin-top:10px;font-size:12px;
      span{display:inline-block;}
      a{color:#FE2D55;}
      /deep/.van-checkbox__label{font-size:12px;color:#FE2D55;}
    }
    .foot{color:hsla(0,0%,100%,.5);text-align:center;margin-top:10px;font-size:12px;line-height:20px;}
  }
  @keyframes paybtn_scalc{
    0% {
      -webkit-transform:scale(0.9);
      transform: scale(0.9);
    }
    50% {
      -webkit-transform:scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform:scale(0.9);
      transform: scale(0.9);
    }
  }
</style>
