var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.oss+'logo.png'}}),_c('div',{staticClass:"floats_right"},[_c('a',{on:{"click":()=>{
      _vm.contentHtml = _vm.textInfo.rule
      _vm.contentHtmlModal = true
    }}},[_c('img',{attrs:{"src":_vm.oss+'activity_rule_icon.png'}})]),_c('router-link',{attrs:{"to":"/service"}},[_c('img',{attrs:{"src":_vm.oss+'kefu_refund_icon.png'}})])],1),_c('div',{staticClass:"ui_refill"},[_c('div',{staticClass:"title_1"},[_vm._v(_vm._s(_vm.amount)+"元享开盒子会员享")]),(_vm.logsList.length)?_c('div',{staticClass:"logsWrap"},[_c('vue-seamless-scroll',{staticClass:"logs",attrs:{"data":_vm.logsList,"class-option":_vm.optionHover}},_vm._l((_vm.logsList),function(item,key){return _c('div',{key:key,staticClass:"item"},[_c('img',{staticClass:"headimg",attrs:{"src":(item.avatar=='' || item.avatar==null)?_vm.noheadimg:item.avatar}}),_c('span',[_vm._v(_vm._s(item.name)+"的账户")])])}),0)],1):_vm._e(),_c('div',{staticClass:"fonts"},[_c('label',[_vm._v("￥")]),_c('count-to',{attrs:{"start-val":_vm.start_val,"end-val":_vm.end_val,"duration":1000}})],1),_c('div',{staticClass:"phone"},[_c('van-field',{ref:"phone",attrs:{"type":"tel","border":false,"placeholder":"请输入您的手机号码"},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('van-icon',{attrs:{"class-prefix":"icon","name":"mobile","size":"20","color":"#444"}})]},proxy:true}]),model:{value:(_vm.json.phone),callback:function ($$v) {_vm.$set(_vm.json, "phone", $$v)},expression:"json.phone"}})],1),_c('div',{staticClass:"paybtn"},[_c('van-button',{attrs:{"round":"","block":""},on:{"click":_vm.onsubmit}})],1),_c('div',{staticClass:"tips"},[_c('span',[_c('van-checkbox',{attrs:{"icon-size":"16px","checked-color":"#FE2D55","shape":"square"},model:{value:(_vm.isread),callback:function ($$v) {_vm.isread=$$v},expression:"isread"}},[_vm._v("我已阅读并同意")])],1),_c('a',{on:{"click":()=>{
          _vm.contentHtml = _vm.textInfo.privacy_policy
          _vm.contentHtmlModal = true
        }}},[_vm._v("《用户隐私协议》")]),_c('a',{on:{"click":()=>{
          _vm.contentHtml = _vm.textInfo.user_agreement
          _vm.contentHtmlModal = true
        }}},[_vm._v("《用户协议》")])])]),_c('div',{staticClass:"foot"},[_c('p',[_vm._v("客服电话：4006178158")]),_c('p',[_vm._v(_vm._s(_vm.textInfo.company_name))])]),(_vm.contentHtmlModal)?_c('van-popup',{staticClass:"contentPop",attrs:{"overlay-style":{backgroundColor:'rgba(0,0,0,.5)'},"close-on-click-overlay":false},model:{value:(_vm.contentHtmlModal),callback:function ($$v) {_vm.contentHtmlModal=$$v},expression:"contentHtmlModal"}},[_c('div',{staticClass:"html",domProps:{"innerHTML":_vm._s(_vm.contentHtml)}}),_c('div',{staticClass:"close"},[_c('van-button',{staticStyle:{"width":"60%"},attrs:{"type":"primary","round":"","color":"#e15ae8"},on:{"click":()=>{_vm.contentHtmlModal = false}}},[_vm._v("确定")])],1)]):_vm._e(),(_vm.RetentionModal)?_c('nopay',{attrs:{"visible":_vm.RetentionModal,"money":_vm.money},on:{"close":()=>{
    this.clearTime()
    this.RetentionModal = false
  },"onsubmit1":()=>{
    this.getCreateOrder(_vm.json)
  },"onsubmit2":()=>{
    this.finishPay(_vm.json.order_sn)
  }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }